<template>
  <div class="c-template--col-2__container">
    <section>
      <div class="c-height-fluid c-content-vertical-align">
        <div class="c-content">
          <center>
            <scharff-logo />
            <div>
              <div class="c-form c-form--sign-in">
                <h2>Recuperar Contraseña</h2>
                <form>
                  <InputText
                    email
                    label="Correo electrónico"
                    autocomplete="email"
                    placeholder="Ingresa tu correo electrónico."
                    v-model="email"
                  />
                  <div class="c-list-buton">
                    <ButtonDefinitive
                      label='Enviar Correo'
                      @click="handleSubmit"
                    />
                  </div>
                </form>
              </div>
              <p>
                *Se enviará un correo electrónico a la dirección que coloque
                para restrablecer su contraseña.
              </p>
            </div>
          </center>
        </div>
      </div>
    </section>
    <div class='img_container'>
      <div class='image_register'>
        <div class='text-5xl-bold img_text'>Contigo en cada paso de tu envío</div>
        <div class='img_illustration' style='right: 40px'>
          <img src="../assets/img/default/illustration-logIn.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScharffLogo from "../components/General/ScharffLogo.vue";
import InputText from "../components/Form/InputText.vue";
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: "ForgotPassword",
  title: "Recuperar Contraseña",
  components: {
    ScharffLogo, InputText, ButtonDefinitive
  },
  data() {
    return {
      email: "",
    };
  },
  mounted() {},
  methods: {
    handleSubmit() {
      this.showLoading();
      this.$store
        .dispatch("FORGOTPASSWORD", {
          email: this.email,
          tipo: "CLU",
        })
        .then((success) => {
          if (success) {
            this.showOk(success.data.message, this.toHome);
          } else {
            this.showError("Revise sus datos e intente nuevamente.");
          }
        })
        .catch((error) => {
          console.error(error);
          if (error === false) {
            this.showError("Revise sus datos e intente nuevamente.");
          } else {
            this.showError("Error en el sistema.");
          }
        });
    },
    toHome() {
      this.$router.push("/signIn");
    },
  },
};
</script>

<style lang='scss' scoped>
.c-template--col-2__container {
  display: grid;
  grid-template-columns: 1fr auto;

  & section {
    display: flex;
    justify-content: center;
    .c-content-vertical-align {
      width: 100%;
      max-width: 560px !important;
    }
  }

  .image-register {
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 1000px) {
  .image-register { display: none }
}
</style>

