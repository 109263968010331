<template>
  <div v-if="currentCardSection === 'list'" class='c-content c-content--app c-content--full_height pb-0'>

    <div style='position: sticky; box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05); height: 64px; padding: 8px 20px; background-color: #fff; display: flex; flex-direction: row; align-items: center; justify-content: center'>
      <div class='home-button-nav' @click='handleBackButton'>
        <svg width='24px' height='24px' stroke-width='1.5' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' color='#000000'>
          <path d='M15 6l-6 6 6 6' stroke='#000000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        </svg>
      </div>
      <div class='text-m-bold'>Tarjetas</div>
    </div>

    <div v-if='cards.length > 0' class='cards_body' style='height: 80%; width: 100%; overflow-y: auto;'>

      <div v-for='card in cards' :key='card.cardId' class='cards_body--card'>

        <div style='display: flex; gap: 16px; flex-grow: 1;' @click="cardIdSelected = ''">
          <Icon name='BankCardDouble' />
          <div class='text-m-regular' style='flex-grow: 1;'>
            {{ card.cardBrand + " " + card.cardNumber }}
          </div>
        </div>
        <div @click='cardIdSelected = card.cardId' class='cards_body--card--option_button'>
          <Icon name='Menu-1' />
        </div>

        <div class='menu_drop_down' v-if='card.cardId === cardIdSelected' ref='dropdown'>
          <div class='option' @click='confirmDelete(card.cardId)'>
            <img src='~@/assets/img/icon/delete.svg'>
            <div style='color: #CC454A'>Eliminar</div>
          </div>
        </div>

      </div>

    </div>

    <div v-else style='height: 80%; width: 100%; overflow-y: auto; padding: 32px 20px'>
      <div class="c-notification c-notification c-notification--alert-notification">
        <h5>Importante:</h5>
        <div class="text">
          Para Tarjeta de Crédito/Débito puedes utilizar Visa, MasterCard y
          American Express, si utilizas tarjeta MasterCard Ripley contactase
          con tu banco previamente y valida si cuentas activada la opción de
          pagos online.
        </div>
      </div>
    </div>

    <ButtonDefinitive label="Agregar tarjeta" @click="setSection('addedit')" />

    <modal-options-card id="modalCardvisa" />

    <modal-card-delete id="modalCardDelete" />
  </div>

  <div v-else class='c-content c-content--app c-content--full_height pb-0'>
    <div style='position: sticky; box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05); height: 64px; padding: 8px 20px; background-color: #fff; display: flex; flex-direction: row; align-items: center; justify-content: center'>
      <div class='home-button-nav' @click='handleBackButton'>
        <svg width='24px' height='24px' stroke-width='1.5' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' color='#000000'>
          <path d='M15 6l-6 6 6 6' stroke='#000000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        </svg>
      </div>
      <div class='text-m-bold'>Agregar tarjeta</div>
    </div>
    <add-edit-card />
  </div>
</template>

<script>
import ModalOptionsCard from "../components/Modals/ModalOptionsCard.vue";
import ModalCardDelete from "../components/Modals/ModalCardDelete.vue";
import AddEditCard from "../components/General/AddEditCard.vue";
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'
import Icon from '@/components/Icon.vue'

export default {
  components: { ModalOptionsCard, ModalCardDelete, AddEditCard, ButtonDefinitive, Icon },
  name: "CardsView",
  title: "Mis Tarjetas - Sharf",
  data() {
    return {
      isInFormView: false,
      cardIdSelected: ''
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.$store.dispatch("STYLE_MAP_SAIO", false);
      this.closeSidebar();
      this.showLoading();
      await this.fetchCards();
      this.$swal.close();
    });
  },
  methods: {
    handleBackButton() {
      if (this.currentCardSection === 'addedit') return this.$store.dispatch("HELPER_SET_CURRENT_CARD_SECTION", 'list')
      window.location.href = process.env.VUE_APP_WEBURL;
    },
    async confirmDelete(cardId) {
      const response = await this.$swal({
        title: 'Confirme',
        html: '¿Desea eliminar esta tarjeta?',
        icon: 'question',
        confirmButtonText: 'Aceptar',
        showDenyButton: true,
        denyButtonText: 'Cancelar'
      })

      let isOk = false
      if (response.isConfirmed){
        this.showLoading()
        await this.$store
          .dispatch("DELETE_CARD", cardId)
          .then((success) => {
            this.$swal.close()
            if (success.status === 200) {
              isOk = true
              this.showOk("Tarjeta eliminada exitosamente!")
            } else {
              this.showError(success.data.message)
            }
          })
          .catch((error) => {
            this.showError(
              error ? error.data.message : "Ocurrió un error interno del sistema."
            )
          })
      }

      this.cardIdSelected = ''
      if (isOk === true) return this.fetchCards()


    },
    setSection(value) {
      this.$store.dispatch("HELPER_SET_CURRENT_CARD_SECTION", value);
    },
    async fetchCards() {
      await this.$store
        .dispatch("GET_CARDS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const cards = success.data.result;
            this.$store.dispatch("GENERAL_SET_CARDS", cards);
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    openOptionCard(card) {
      this.$store.dispatch("HELPER_SET_CURRENT_CARD_TO_EDIT", card);
      this.openModalDismissible("modalCardvisa");
    },
  },
  computed: {
    cards() {
      return this.$store.getters.GENERAL_CARDS;
    },
    currentCardSection: {
      get() {
        return this.$store.getters.HELPER_CURRENT_CARD_SECTION;
      },
      set(value) {
        this.$store.dispatch("HELPER_SET_CURRENT_CARD_SECTION", value);
      },
    },
  },
};
</script>

<style lang='scss' scoped>
.c-content--full_height {
  padding: 0 !important;

  .cards_body {
    display: flex;
    flex-direction: column;

    &--card {
      position: relative;
      border-bottom: 1px solid #D0D0D0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 8px 20px 20px;

      .menu_drop_down {
        margin-top: 80px;
        position: absolute;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 8px 16px -4px rgba(0, 0, 0, 0.20);
        border-radius: 16px;
        z-index: 99;
        background-color: white;
        overflow: hidden;
        right: 0;

        .option {
          padding: 12px 20px;
          display: flex; gap: 16px; cursor: pointer; transition: .3s;
          &:hover { background-color: #e6e6e6 }
        }
      }

      &--option_button {
        padding: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        transition: .3s;
        cursor: pointer;
        &:hover { background: rgba(0, 0, 0, 0.16) }
      }
    }
  }
}
</style>
