<template>
  <div
    class="c-content c-content--app c-content--full_height pb-0"
    :class="progressNav.step !== 6 ? 'c-content--mobile-spacing-top' : ''"
  >
    <app-progress-nav
      :helpModalId="progressNav.helpModalId"
      :progress="progressNav.progress"
      :showButtonBack="progressNav.showButtonBack"
      :stepsText="progressNav.stepsText"
      :title="progressNav.title"
      :visiblePc="progressNav.visiblePc"
      v-show="progressNav.show === true"
    ></app-progress-nav>

    <step-1-content v-if="progressNav.step === 1" />
    <step-1-footer v-if="progressNav.step === 1" />

    <step-2-content v-if="progressNav.step === 2" />
    <step-2-footer v-if="progressNav.step === 2" />

    <step-3-content v-if="progressNav.step === 3" />
    <step-3-footer v-if="progressNav.step === 3" />

    <step-4-content v-if="progressNav.step === 4" />
    <step-4-footer v-if="progressNav.step === 4" />

    <step-5-content v-if="progressNav.step === 5" />
    <step-5-footer v-if="progressNav.step === 5" />

    <order-confirmation-content v-if="progressNav.step === 6" />
    <order-confirmation-footer v-if="progressNav.step === 6" />

    <modal-restriction />
    <modal-quoter />
    <modal-detail-quote :resQuote="totalFare" />
    <modal-address id="modal-add-address" :disableCloseButton="'false'" />
    <modal-favorite-contacts
      id="modal-contact"
      @getFavoriteContact="getContact"
    />

    <modal-coupon id="modalCoupon" @getSelectedCoupon="getCoupon" />

    <modal-type-voucher
      id="modalTypeVoucher"
      @getVoucherData="getVoucherData"
    />

    <modal-type-card id="modalTypeCard" @getCardData="getCardData" />
  </div>
</template>

<script>
import M from "materialize-css";
import OrderConfirmationContent from "../components/CompraTrae/OrderConfirmation/OrderConfirmation-Content.vue";
import OrderConfirmationFooter from "../components/CompraTrae/OrderConfirmation/OrderConfirmation-Footer.vue";
import Step1Content from "../components/CompraTrae/Step1/Step1-Content.vue";
import Step1Footer from "../components/CompraTrae/Step1/Step1-Footer.vue";
import Step2Content from "../components/CompraTrae/Step2/Step2-Content.vue";
import Step2Footer from "../components/CompraTrae/Step2/Step2-Footer.vue";
import Step3Content from "../components/CompraTrae/Step3/Step3-Content.vue";
import Step3Footer from "../components/CompraTrae/Step3/Step3-Footer.vue";
import Step4Content from "../components/CompraTrae/Step4/Step4-Content.vue";
import Step4Footer from "../components/CompraTrae/Step4/Step4-Footer.vue";
import Step5Content from "../components/CompraTrae/Step5/Step5-Content.vue";
import Step5Footer from "../components/CompraTrae/Step5/Step5-Footer.vue";
import AppProgressNav from "../components/General/AppProgressNav.vue";
import ModalRestriction from "../components/Modals/ModalCTRestrictions.vue";
import ModalQuoter from "../components/Modals/ModalQuoter.vue";
import ModalAddress from "../components/Modals/ModalAddress.vue";
import ModalFavoriteContacts from "../components/Modals/ModalFavoriteContacts.vue";
import ModalCoupon from "../components/Modals/ModalCoupon.vue";
import ModalTypeCard from "../components/Modals/ModalTypeCard.vue";
import ModalTypeVoucher from "../components/Modals/ModalTypeVoucher.vue";
import ModalDetailQuote from "../components/CompraTrae/Modals/DetailQuote.vue";

export default {
  name: "ComprayTrae",
  title: "Compra y Trae - Sharf",
  components: {
    AppProgressNav,
    Step1Content,
    Step1Footer,
    Step2Content,
    Step2Footer,
    Step3Content,
    Step3Footer,
    Step4Content,
    Step4Footer,
    Step5Content,
    Step5Footer,
    ModalRestriction,
    ModalQuoter,
    ModalAddress,
    ModalFavoriteContacts,
    OrderConfirmationContent,
    OrderConfirmationFooter,
    ModalCoupon,
    ModalTypeCard,
    ModalTypeVoucher,
    ModalDetailQuote,
  },
  data() {
    return {};
  },
  beforeMount() {
    this.authenticated = this.$store.getters.AUTHENTICATED;
  },
  mounted() {
    this.$nextTick(() => {
      this.initMain();
      this.$store.dispatch("COMPRATRAE_PROGRESS_NAV_STEP", 1);
      this.$store.dispatch("CURRENT_SERVICE", 3);
      this.getDefaultContact();
      const btnOpenModalCTRestriction = document.querySelectorAll(
        "#btnOpenModalCTRestriction"
      );
      const modalCTRestriction = document.querySelector("#modalRestriction");

      if (btnOpenModalCTRestriction) {
        btnOpenModalCTRestriction.forEach((items) => {
          items.addEventListener("click", () => {
            const instance3 = M.Modal.getInstance(modalCTRestriction);
            if (instance3) instance3.open();
          });
        });
      }
      this.openModal("modalInfoAlmacen");
    });
  },
  methods: {
    getDefaultContact() {
      this.$store.dispatch("GET_DEFAULT_CONTACT").then((response) => {
        if (response?.EsPredeterminado) {
          const contact = {
            contactId: response.contactId,
            name: response.nombreContacto,
            phone: response.telefonoContacto,
            email: response.correoContacto,
            documentNumber: response.nroDocumentoContacto,
          };
          this.$store.dispatch("COMPRATRAE_ORDER_DESTINATION_CONTACT", contact);
        }
      });
    },
    getContact(Contact) {
      if (this.modalContactType === "destination") {
        const destinationContact = {
          contactId: Contact.contactId,
          name: Contact.nombreContacto,
          phone: Contact.telefonoContacto,
        };
        this.$store.dispatch(
          "COMPRATRAE_ORDER_DESTINATION_CONTACT",
          destinationContact
        );
      } else {
        this.showError("Modal tipo de contacto no reconocido.");
      }
    },
    getCardData(cardData) {
      this.$store.dispatch("COMPRATRAE_CARD", cardData);
    },
    getVoucherData(voucherData) {
      this.$store.dispatch("COMPRATRAE_VOUCHER", voucherData);
    },
    async getCoupon(coupon) {
      this.$store.dispatch("COMPRATRAE_COUPON", coupon);

      const order = this.$store.getters.COMPRATRAE_ORDER;

      const requestData = {
        weight: order.weight,
        value: order.value,
        provinceId: order.destination.provinceId,
        couponId: order.coupon !== null ? order.coupon.id : null,
      };

      await this.$store
        .dispatch("COMPRATRAE_CALCULATE_FARE", requestData)
        .then((data) => {
          this.$store.dispatch("COMPRATRAE_ORDER_FARE", data);
        })
        .catch((error) => {
          this.showError(error);
        });
    },
  },
  computed: {
    progressNav() {
      return this.$store.getters.COMPRATRAE_PROGRESS_NAV;
    },
    stylesCT() {
      return this.$store.getters.COMPRATRAE_STYLES;
    },
    expressDiv() {
      return this.$store.getters.COMPRATRAE_CONTENT;
    },
    modalContactType() {
      return this.$store.getters.MODAL_CONTACT_TYPE;
    },
    totalFare: {
      get() {
        return this.$store.getters.COMPRATRAE_ORDER_FARE;
      },
    },
  },
  watch: {
    stylesCT: {
      handler() {
        this.$store.dispatch("STYLE_SHOW_CT_QUOTER", this.stylesCT.showQuoter);
        this.$store.dispatch("STYLE_MAP_SAIO", this.stylesCT.mapSaio);
        this.$store.dispatch("STYLE_CUSTOM_DIV", this.stylesCT.generalDiv);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.close-btn-modal {
  display: none;
}
</style>
