<template><div></div></template>

<script>
export default {
  name: "externalLogin",
  data() {
    return {
      user: {},
    };
  },
  mounted() {
    const token = this.$route.query.token?.replaceAll(" ", "+");
    const accessToken = this.$route?.query?.accessToken;
    if (token) {
      this.showLoading("Cargando...");
      this.user = this.$store
        .dispatch("LOGINFROMTOKEN", token)
        .then(() => {
          window.location.href = process.env.VUE_APP_WEBURL;
        })
        .catch(() => {
          this.showError(
            "No se pudo iniciar sesión, intente nuevamente",
            null,
            () => {
              window.location.href = process.env.VUE_APP_WEBURL;
            }
          );
          Error("Error al obtener el detalle del pedido");
        });
    } else if (accessToken) {
      this.showLoading("Cargando...");
      this.$store
        .dispatch("FB_LOGIN", {accessToken, queryString: ''})
        .then((success) => {
          if (success.status === 200) {
            this.$store.dispatch("USER_SET_USER", success.data.result);
            window.location.href = process.env.VUE_APP_WEBURL;
          } else {
            this.showError(success.data.message);
          }
        })
        .catch(() => {
          const msg = "No se pudo iniciar sesión, intente nuevamente";
          this.showError(msg, null, () => {
            window.location.href = process.env.VUE_APP_WEBURL;
          });
        });
    } else {
      window.location.href = process.env.VUE_APP_WEBURL;
    }
  },
};
</script>

<style></style>
