<template>
  <div class="c-template--col-2__container">
    <section>
      <div class="c-height-fluid c-content-vertical-align">
        <div class="c-content">
          <center>
            <scharff-logo />
            <div>
              <div class="c-form c-form--sign-in">
                <h2>Recuperar Contraseña</h2>
                <form class="restore-password-form">
                  <InputText
                    password
                    label="Contraseña"
                    placeholder="Crea una contraseña"
                    v-model="password"
                  />
                  <InputText
                    password
                    label="Confirmar Contraseña"
                    placeholder="Crea una contraseña"
                    v-model="confirmPassword"
                  />
                  <!-- <div class="c-input input-field">
                    <input
                      type="password"
                      id="user-password"
                      v-model="password"
                    />
                    <label for="user-password" class="">Contraseña</label>
                  </div> -->
                  <!-- <div class="c-input input-field">
                    <input
                      type="password"
                      id="user-confirm-password"
                      v-model="confirmPassword"
                    />
                    <label for="user-confirm-password" class=""
                      >Confirmar Contraseña</label
                    >
                  </div> -->
                  <div class="c-list-buton">
                    <a
                      href="javascript:void(0)"
                      @click.prevent="handleSubmit"
                      class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
                      >Restablecer Contraseña</a
                    >
                  </div>
                </form>
              </div>
            </div>
          </center>
        </div>
      </div>
    </section>
    <div class='img-container'>
      <div class='image-register'>
        <div class='text-5xl-bold img-text'>Contigo en cada paso de tu envío</div>
        <div class='img-illustration' style='right: 40px'>
          <img src="../assets/img/default/illustration-logIn.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScharffLogo from "../components/General/ScharffLogo.vue";
import InputText from "../components/Form/InputText.vue";

export default {
  name: "RestorePassword",
  title: "Recuperar Contraseña",
  components: {
    ScharffLogo, InputText
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
    };
  },
  mounted() {},
  methods: {
    handleSubmit() {
      this.showLoading();
      const codigo = this.$store.getters.GET_CODE_RESTPRE_PASSWORD;
      this.$store
        .dispatch("RESTOREPASSWORD", {
          password: this.password,
          confirmPassword: this.confirmPassword,
          Codigo: codigo,
        })
        .then((success) => {
          if (success) {
            this.showOk(success.data.message, this.toHome);
          } else {
            this.showError("Revise sus datos e intente nuevamente.");
          }
        })
        .catch((error) => {
          if (error === false) {
            this.showError("Revise sus datos e intente nuevamente.");
          } else {
            this.showError("Error en el sistema.");
          }
        });
    },
    toHome() {
      this.$router.push("/signIn");
    },
  },
};
</script>

<style lang="scss" scoped>
.restore-password-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.c-template--col-2__container {
  display: grid;
  grid-template-columns: 1fr auto;
  .image-register {
    height: 100%;
    object-fit: cover;
  }
}
@media (max-width: 1000px) {
  .image-register { display: none }
}
</style>
