<template>
  <main class="main" role="main">
    <navigator :isAuthenticated="authenticated" />
    <div :class="generalDiv">
      <section class="pb-0">
        <!-- INICIO CONTENIDO -->
        <router-view name="b" />

        <!-- FIN CONTENIDO -->
      </section>
      <section>
        <map-saio id="mapSaio" v-show="showMapSaio" />
        <div
          v-if="!showMapSaio"
          class="c-box--app-background c-image--background-home"
        ></div>
        <ct-quoter v-if="showCTQuoter" />
        <pm-quoter v-if="showPMQuoter" />
        <div
          v-if="step === 3 && typeService === 2"
          class="c-box--app-background c-image--programmed-step-3"
        ></div>
        <ServiceInfo :title="'Service'" :description="'dasdas'" />
      </section>
    </div>
    <!-- <modal-programmed-package id="modal-package"></modal-programmed-package> -->
  </main>
</template>

<script>
import MapSaio from "../components/General/MapSaio.vue";
import Navigator from "../components/General/Navigator.vue";
import CtQuoter from "../components/CompraTrae/QuoterContainer.vue";
import PmQuoter from "../components/Programmed/QuoterContainer.vue";
import ServiceInfo from "../components/Home/ServiceInfo.vue";

// import ModalProgrammedPackage from "../components/Modals/ModalProgrammedPackage.vue";
export default {
  name: "HomeView",
  title: "Inicio - Sharf",
  components: {
    Navigator,
    MapSaio,
    CtQuoter,
    // ModalProgrammedPackage,
    PmQuoter,
    ServiceInfo,
  },
  computed: {
    authenticated() {
      return this.$store.getters.AUTHENTICATED;
    },
    generalDiv() {
      return this.$store.getters.STYLE_GENERAL_DIV;
    },
    showMapSaio() {
      return this.$store.getters.STYLE_SHOW_MAP_SAIO;
    },
    showCTQuoter() {
      return this.$store.getters.STYLE_SHOW_CT_QUOTER;
    },
    showPMQuoter() {
      return this.$store.getters.STYLE_SHOW_PM_QUOTER;
    },
    step() {
      return this.$store.getters.PROGRAMMED_ORDER_STEP;
    },
    typeService() {
      // TODO
      return this.$store.getters.CURRENT_SERVICE;
    },
  },
  beforeMount() {
    this.$store.dispatch("STYLE_HOME_DIV");
    this.$store.dispatch("STYLE_HOME_DIV");
    this.$store.dispatch("STYLE_MAP_SAIO", false);
    this.initMain();
  },
  mounted() {
    const alreadyShowModalStart =
      localStorage.getItem("alreadyShowModalStart") || false;
    const modalOnboardingAppStart = document.getElementById(
      "modalOnboardingAppStart"
    );
    if (modalOnboardingAppStart && !alreadyShowModalStart)
      modalOnboardingAppStart.style.display = "block";
    const $this = this;
    // this.$nextTick(() => {
    //   if (this.authenticated) {
    //     this.$zendesk.load("18e84ac4-4464-4042-8e1c-1ea66f9a4475");
    //     this.$zendesk.$on("loaded", () => {
    //       window.zESettings = {
    //         webWidget: {
    //           authenticate: {
    //             chat: {
    //               jwtFn(callback) {
    //                 $this.$store
    //                   .dispatch("GET_ZENDESK_TOKEN")
    //                   .then((response) => {
    //                     callback(response);
    //                   });
    //               },
    //             },
    //           },
    //           chat: {
    //             suppress: false,
    //             title: {
    //               "*": "Chat en vivo",
    //             },
    //             concierge: {
    //               name: "Scharff",
    //               title: { "*": "En línea" },
    //             },
    //           },
    //           contactForm: {
    //             suppress: true,
    //           },
    //           helpCenter: {
    //             suppress: true,
    //           },
    //           talk: {
    //             suppress: true,
    //           },
    //           answerBot: {
    //             suppress: true,
    //           },
    //           launcher: {
    //             chatLabel: {
    //               "*": "Iniciar chat",
    //             },
    //             mobile: {
    //               labelVisible: true,
    //             },
    //           },
    //           color: {
    //             theme: "#ff7177",
    //             launcher: "#ff7177", // This will also update the badge
    //             launcherText: "#FFF",
    //             button: "#ff7177",
    //             resultLists: "#ff7177",
    //             header: "#ff7177",
    //             articleLinks: "#ff7177",
    //           },
    //         },
    //       };
    //       this.$zendesk.show();
    //     });
    //   }
    // });
    if ($this.$store.getters.NEWCLIENT === true) {
      $this.showWarning(
        "Por favor complete sus datos antes de realizar un pedido en Mi Perfil"
      );
    }
  },
  updated() {
    if (this.$route.name === "Home") {
      this.$store.dispatch("STYLE_HOME_DIV");
      this.$store.dispatch("STYLE_MAP_SAIO", false);
      this.$store.dispatch("STYLE_SHOW_PM_QUOTER", false);
      this.$store.dispatch("STYLE_SHOW_CT_QUOTER", false);
      this.initMain();
    }
  },
};
</script>
