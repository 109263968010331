<template>
  <div :class="expressDiv">
    <app-progress-nav
      :helpModalId="progressNav.helpModalId"
      :progress="progressNav.progress"
      :showButtonBack="progressNav.showButtonBack"
      :stepsText="progressNav.stepsText"
      :title="progressNav.title"
      :visiblePc="progressNav.visiblePc"
      v-if="progressNav.show === true"
    ></app-progress-nav>

    <step-1-content v-show="progressNav.step === 1" />
    <step-1-footer v-show="progressNav.step === 1" />

    <step-2-content v-show="progressNav.step === 2" />
    <step-2-footer v-show="progressNav.step === 2" />

    <step-3-content v-show="progressNav.step === 3" />
    <step-3-footer v-show="progressNav.step === 3" />

    <order-confirmation-content v-if="progressNav.step === 4" />
    <order-confirmation-footer v-if="progressNav.step === 4" />

    <modal-express-slider />

    <modal-express-restrictions />

    <modal-coupon id="modalCoupon" @getSelectedCoupon="getCoupon" />

    <modal-type-voucher
      id="modalTypeVoucher"
      @getVoucherData="getVoucherData"
    />

    <modal-detail-quote :resQuote="fare" />

    <modal-tip id="modalTip" @getSelectedTip="getTip" />

    <modal-type-card id="modalTypeCard" @getCardData="getCardData" />

    <modal-product />

    <modal-address id="modal-add-address" :disableCloseButton="'false'" />

    <modal-favorite-contacts
      id="modal-contact"
      @getFavoriteContact="getContact"
    />
  </div>
</template>

<script>
import OrderConfirmationContent from "../components/Express/OrderConfirmation/OrderConfirmation-Content.vue";
import OrderConfirmationFooter from "../components/Express/OrderConfirmation/OrderConfirmation-Footer.vue";
import Step1Content from "../components/Express/Step1/Step1-Content.vue";
import Step1Footer from "../components/Express/Step1/Step1-Footer.vue";
import Step2Content from "../components/Express/Step2/Step2-Content.vue";
import Step2Footer from "../components/Express/Step2/Step2-Footer.vue";
import Step3Content from "../components/Express/Step3/Step3-Content.vue";
import Step3Footer from "../components/Express/Step3/Step3-Footer.vue";
import AppProgressNav from "../components/General/AppProgressNav.vue";
import ModalAddress from "../components/Modals/ModalAddress.vue";
import ModalCoupon from "../components/Modals/ModalCoupon.vue";
import ModalExpressRestrictions from "../components/Modals/ModalExpressRestrictions.vue";
import ModalExpressSlider from "../components/Modals/ModalExpressSlider.vue";
import ModalProduct from "../components/Modals/ModalProduct.vue";
import ModalTip from "../components/Modals/ModalTip.vue";
import ModalTypeCard from "../components/Modals/ModalTypeCard.vue";
import ModalTypeVoucher from "../components/Modals/ModalTypeVoucher.vue";
import ModalFavoriteContacts from "../components/Modals/ModalFavoriteContacts.vue";
import Map from "../commons/mixins/maps";
import ModalDetailQuote from "../components/General/DetailQuote.vue";

export default {
  name: "ExpressView",
  title: "Express - Sharf",
  components: {
    AppProgressNav,
    Step1Content,
    Step1Footer,
    Step2Footer,
    Step2Content,
    Step3Content,
    Step3Footer,
    OrderConfirmationContent,
    OrderConfirmationFooter,
    ModalExpressSlider,
    ModalExpressRestrictions,
    ModalCoupon,
    ModalTypeVoucher,
    ModalTip,
    ModalTypeCard,
    ModalProduct,
    ModalAddress,
    ModalFavoriteContacts,
    ModalDetailQuote,
  },
  data() {
    return {
      contactData: null,
    };
  },
  beforeMount() {
    this.authenticated = this.$store.getters.AUTHENTICATED;
    this.$store.dispatch("STYLE_SERVICES_W_MAP_DIV");
  },
  mounted() {
    if (sessionStorage.getItem("beenhere") === "1") {
      sessionStorage.setItem("beenhere", "0");
      window.location = process.env.VUE_APP_WEBURL;
    } else {
      sessionStorage.setItem("beenhere", "1");
    }

    this.$nextTick(() => {
      this.$store.dispatch("STYLE_SERVICES_W_MAP_DIV");
      this.$store.dispatch("CURRENT_SERVICE", 1);
      this.initMain();
      const map = new Map();
      map.initMap("mapSaio");
      this.$store.dispatch("STYLE_MAP_SAIO", true);
      this.$store.dispatch("EXPRESS_MAP", map);
    });
    this.getDefaultContact();
  },
  computed: {
    progressNav() {
      return this.$store.getters.EXPRESS_PROGRESS_NAV;
    },
    expressDiv() {
      return this.$store.getters.STYLE_EXPRESS_DIV;
    },
    reload() {
      return this.$store.getters.RELOAD;
    },
    modalContactType() {
      return this.$store.getters.MODAL_CONTACT_TYPE;
    },
    fare() {
      return this.$store.getters.EXPRESS_FARE;
    },
    user() {
      return this.$store.getters.USER;
    },
  },
  beforeUnmount() {},
  methods: {
    getCardData(cardData) {
      this.$store.dispatch("EXPRESS_CARD", cardData);
    },
    getDefaultContact() {
      this.$store.dispatch("GET_DEFAULT_CONTACT").then((response) => {
        if (response?.EsPredeterminado) {
          const contact = {
            contactId: response.contactId,
            name: response.nombreContacto,
            phone: response.telefonoContacto,
            email: response.correoContacto,
            documentNumber: response.nroDocumentoContacto,
          };
          this.$store.dispatch("EXPRESS_ORDER_ORIGIN_CONTACT", contact);
          this.$store.dispatch("EXPRESS_ORDER_DESTINATION_CONTACT", contact);
        }
      });
    },
    getContact(Contact) {
      if (this.modalContactType === "origin") {
        const oriContact = {
          contactId: Contact.contactId,
          name: Contact.nombreContacto,
          phone: Contact.telefonoContacto,
          email: Contact.correoContacto,
          documentNumber: Contact.nroDocumentoContacto,
        };
        this.$store.dispatch("EXPRESS_ORDER_ORIGIN_CONTACT", oriContact);
      } else if (this.modalContactType === "destination") {
        const currentDestinationIndex =
          this.$store.getters.EXPRESS_CURRENT_CONTACT_DESTINATION_INDEX;
        const destContact = {
          contactId: Contact.contactId,
          name: Contact.nombreContacto,
          phone: Contact.telefonoContacto,
          email: Contact.correoContacto,
          documentNumber: Contact.nroDocumentoContacto,
          destinationIndex: currentDestinationIndex,
        };
        this.$store.dispatch("EXPRESS_ORDER_DESTINATION_CONTACT", destContact);
      } else {
        this.showError("Modal tipo de contacto no reconocido.");
      }
    },
    getVoucherData(voucherData) {
      this.$store.dispatch("EXPRESS_VOUCHER", voucherData);
    },
    async getTip(tip) {
      this.$store.dispatch("EXPRESS_TIP", tip);
      this.showLoading();
      await this.$store.dispatch("EXPRESS_CALCULATE_FARE");
      this.$swal.close();
    },
    async getCoupon(coupon) {
      this.$store.dispatch("EXPRESS_COUPON", coupon);
      this.showLoading();
      await this.$store.dispatch("EXPRESS_CALCULATE_FARE");
      this.$swal.close();
    },
  },
};
</script>

<style></style>
