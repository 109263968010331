<template>
  <div class="c-content c-content--app c-content--full_height pb-0">
    <div>
      <div class="c-nav c-nav--floating-top nav_styles_v2" @click='handleBackBtn'>
        <div class='home-button-nav'>
          <Icon name='arrow-left' />
        </div>
        <div class="text-s-bold">Mis envíos</div>
      </div>
    </div>
    <resume v-if="currentStep === 1" />
    <resume-footer v-if="currentStep === 1" />

    <order-details v-if="currentStep === 2" />
    <order-details-footer v-if="currentStep === 2" />
  </div>
</template>

<script>
import Resume from "../components/History/History.vue";
import ResumeFooter from "../components/History/HistoryFooter.vue";
import OrderDetails from "../components/History/OrderDetailsV2.vue";
import OrderDetailsFooter from "../components/History/OrderDetailsFooter.vue";
import Icon from '@/components/Icon.vue'

export default {
  name: "HistoryView",
  components: {
    Resume,
    ResumeFooter,
    OrderDetails,
    OrderDetailsFooter,
    Icon,
  },
  beforeMount() {
    this.authenticated = this.$store.getters.AUTHENTICATED;
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch("STYLE_MAP_SAIO", false);
      this.closeSidebar();
      // this.$store.dispatch("STYLE_SERVICES_W_MAP_DIV");
      this.initMain();
      this.initSaioMap();

      if (sessionStorage.getItem("beenhere") === "1") {
        sessionStorage.setItem("beenhere", "0");
        window.location = process.env.VUE_APP_WEBURL;
      } else {
        sessionStorage.setItem("beenhere", "1");
      }
    });
  },
  computed: {
    progressNav() {
      return this.$store.getters.EXPRESS_PROGRESS_NAV;
    },
    expressDiv() {
      return this.$store.getters.STYLE_EXPRESS_DIV;
    },
    reload() {
      return this.$store.getters.RELOAD;
    },
    modalContactType() {
      return this.$store.getters.MODAL_CONTACT_TYPE;
    },
    currentStep() {
      return this.$store.getters.HISTORY_CURRENT_STEP;
    },
  },

  methods: {
    handleBackBtn() {
      if (this.currentStep === 1) {
        window.location = process.env.VUE_APP_WEBURL;
      } else {
        this.$store.dispatch("HISTORY_CURRENT_STEP", 1);
      }
    },
  },
};
</script>

<style scoped>
.nav_styles_v2 {
  padding: 0;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);

  display: flex;
  align-items: center;
  justify-content: center;
}
.c-content--full_height {
  padding: 0 !important;
}
</style>
