<template>
  <div class="c-content c-scrollbar pb-0 c-top-space c-content--spacing-top">
    <!-- INICIO SIDEBAR  -->
    <div class="c-nav c-nav--floating-top c-nav--menu">
      <div class="row valign-wrapper">
        <div class="col s4">
          <div class='sidenav-trigger' data-target="nav-movile" style='cursor: pointer'>
            <Icon name='Hamburger' />
          </div>
          <div class="sidenav" id="nav-movile">
            <sidebar-content :isAuthenticated="authenticated" />
          </div>
        </div>
        <div class="col s4 c-text-center">
          <img id="logo-favicon" src="~@/assets/img/sharf-logo-filled.svg" />
        </div>
        <div class="col s4 c-text-right">
        </div>
      </div>
    </div>
    <!-- FIN SIDEBAR  -->

    <div class="c-text-center font-weight-bold text-4xl-bold">Bienvenido/a</div>
    <div class="c-text-center text-m-regular" style="color: #737373">Escoge un servicio</div>
    <div class="c-grid c-grid--services">
      <div class="c-grid--services__container">
        <!-- <article
          class="c-grid--services__item"
          @mouseover="updateShowServideDescription('express')"
        >
          <router-link to="/express" @click="expressClick">
            <div class="card c-card c-card--service-box">
              <div class="card-image">
                <img src="~@/assets/img/service-express.svg" />
                <div class="card-title color-text-black">
                  <span class="card-sub-title color-text-black">Servicio</span
                  ><span class="card-option">Express</span>
                </div>
              </div>
            </div>
            <div class="card__item__hover">
              <span>Envios el mismo día en Lima</span>
            </div>
          </router-link>
        </article> -->

        <article @click="itemClick('servicio_programado')" class="c-grid--services__item services" @mouseover="updateShowServideDescription('programmed')">
          <router-link to="/programmed">
            <div class="card c-card c-card--service-box">
              <div class="card-image" style='height: 100%; display: flex; justify-content: center; align-items: center;'>
                <img src="~@/assets/img/service-programmed.svg"/>
              </div>
            </div>
          </router-link>
          <span class="text-m-bold" style='text-align: center'>Envío <br> puerta a puerta</span>
        </article>

        <article v-if='!IS_AN_EMBASSY_USER' @click="itemClick('servicio_puntos')" class="c-grid--services__item services" @mouseover="updateShowServideDescription('puntos')">
          <router-link to="/puntoscharff">
            <div class="card c-card c-card--service-box">
              <div class="card-image" style='height: 100%; display: flex; justify-content: center; align-items: center;'>
                <img src="~@/assets/img/service-points-scharff.svg" />
              </div>
            </div>
          </router-link>
          <span class="text-m-bold" style='text-align: center'>Envío <br> Punto Sharf</span>
        </article>

        <!-- <article
          class="c-grid--services__item"
          @mouseover="updateShowServideDescription('cyt')"
        >
          <router-link to="/compraytrae">
            <div class="card c-card c-card--service-box">
              <div class="card-image">
                <img src="~@/assets/img/service-buy-and-bring.svg" />
                <div class="card-title color-text-black">
                  <span class="card-sub-title color-text-black">Servicio</span
                  ><span class="card-option">Compra y trae</span>
                </div>
              </div>
            </div>
            <div class="card__item__hover">
              <span>Trae tus compras online de USA a Perú ¡Qué esperas!</span>
            </div>
          </router-link>
        </article>-->
      </div>
    </div>
    <br />
    <div class="row" v-if='!IS_AN_EMBASSY_USER'>
      <div class="col s12">
        <article class="c-grid--services__item" style='padding-top: 48px'>
          <ButtonDefinitive
            variant='ghost'
            label='Cotiza un envío'
            @click="openQuoterModal"
          >
            <template v-slot:icon>
              <Icon name='Calculate' />
            </template>
          </ButtonDefinitive>
          <!-- <a href="javascript:void(0)">
            <div class="card c-card c-card--service-box d-flex card-image" v-on:click="openQuoterModal">
              <div class="card-image" style="display: flex; align-items: center; padding: 1em">
                <div class="col s4" style=" display: flex; align-items: center; justify-content: end">
                  <img class="translatex nuevas_tarifas_imagen" src="~@/assets/img/svg/estrellaNuevo.svg"/>
                </div>
                <div class="col s8" style="display: flex; align-items: center">
                  <div style="display: flex; align-items: center">
                    <div class="card-title color-text-black">
                      <span class="card-option">Nuevas tarifas</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a> -->
        </article>
      </div>
    </div>
    <!-- INICIO GUEST FOOTER  -->
    <guest-footer v-if="!authenticated" />
    <!-- FIN GUEST FOOTER -->
  </div>
</template>

<script>
import SidebarContent from "../components/General/SidebarContent.vue";
import GuestFooter from "../components/Home/GuestFooter.vue";
import Icon from '@/components/Icon.vue'
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  components: { GuestFooter, SidebarContent, Icon, ButtonDefinitive },
  name: "DefaultView",
  title: "Inicio - Sharf",
  data() {
    return {
      isTabLoaded: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initMain();
    });
  },
  methods: {
    itemClick(s){
      this.trackEvent(`click_home_${s}`, 'home')
    },
    openQuoterModal() {
      this.trackEvent('cotizador-home');
      const modal = document.querySelector("#modalCalculatorScheduledDelivery");
      const instance = M.Modal.getInstance(modal);
      if (instance) instance.open();

      if (!this.isTabLoaded) {
        window.setTimeout(() => {
          const el = document.querySelector(".tabs");
          M.Tabs.init(el);
        }, 100);

        this.isTabLoaded = true;
      }
    },
    expressClick() {
      this.$store.dispatch("EXPRESS_PROGRESS_NAV_ATTR", {
        progress: "40",
        stepsText: "Paso 1 de 3",
        step: 1,
        visiblePC: "true",
        show: true,
      });
      this.$store.dispatch("EXPRESS_PROGRESS_NAV_VISIBLEPC", "true");
      this.$store.dispatch("STYLE_MAP_SAIO", true);
      this.$store.dispatch("EXPRESS_RESET");
    },
    updateShowServideDescription(type) {
      this.$store.dispatch("SET_SHOW_SERVICE_DESCRIPTION", type);
    },
  },
  computed: {
    IS_AN_EMBASSY_USER() {
      return this.$store.getters.IS_AN_EMBASSY_USER;
    },
    authenticated() {
      return this.$store.getters.AUTHENTICATED;
    },
  },
};
</script>

<style scoped lang='scss'>
a {
  position: relative;
}
.card__item__hover {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 15px;
  visibility: hidden;
  position: absolute;
  bottom: 0px;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  border-radius: 14px;
  background-color: #989393;
  line-height: 1;
  color: white;
  font-size: 20px;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
}
a:hover .card__item__hover {
  visibility: visible;
  opacity: 1;
}

.nuevas_tarifas_imagen {
  margin: 0 !important;
}

.c-text-center {
  img {
    width: 85px !important;
    margin: 0 !important;
  }
}

.c-card--service-box {
  height: 188px;
}

.c-nav--floating-top {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05) !important
}
</style>
