<template>
  <!-- TODO Carlos todo mal.-->
  <div class="c-content c-content--app c-content--full_height pb-0">
    <div>
      <div class="c-nav c-nav--floating-top">
        <div class="c-content-vertical-align">
          <div
            class="c-nav--floating-top__button-back"
            @click="handleBackButton"
          ></div>
          <h6 class="c-text-center">Mi Perfil</h6>
        </div>
      </div>
    </div>
    <div class="c-section--content-tab-c c-m-top-for-nav-md">
      <ul class="c-nav--tab-d-c tabs">
        <li class="c-tab-button tab">
          <a
            href="javascript:void(0)"
            v-bind:class="[type === 'profile' ? 'active' : '']"
            @click="type = 'profile'"
            >Mi perfil
          </a>
        </li>
        <li class="c-tab-button-n tab">
          <a
            href="javascript:void(0)"
            v-bind:class="[type === 'password' ? 'active' : '']"
            @click="type = 'password'"
            >Clave
          </a>
        </li>
      </ul>
    </div>
    <div v-show="type === 'profile'" id="profile" class="c-height active">
      <article class="c-content--profile c-scrollbar">
        <div class="c-grid--services__container-m">
          <div class="c-section--content-article">
            <article class="c-grid--services__item c-section--dateContent">
              <div class="c-section--content-date-designation">
                <h3>Mis datos</h3>
              </div>
              <div v-show="!editProfile" class="c-section--content-date">
                <div class="date-info">
                  <div class="date-info-content">
                    <div class="date-info-content-box">
                      <p class="info-black">Nombre(s)</p>
                      <p class="info-light">{{ user.name }}</p>
                    </div>
                  </div>
                  <div class="date-info-content">
                    <div class="date-info-content-box">
                      <p class="info-black">Apellidos</p>
                      <p class="info-light">
                        {{ user.lastName }} {{ user.motherLastName }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="editProfile" class="c-section--content-date">
                <div class="form-field">
                  <InputText
                    label="Nombre(s)"
                    :maxLength="100"
                    placeholder="Ingresa tu(s) nombre(s)"
                    v-model="user.name"
                    missingErrorMessage="Ingresa tu(s) nombre(s)."
                  />
                  <InputText
                    label="Apellidos"
                    :maxLength="100"
                    placeholder="Ingresa tus apellidos"
                    v-model="user.lastName"
                    missingErrorMessage="Ingresa tus apellidos."
                  />
                  <!-- <InputText
                    isOptional
                    label="Apelllido Materno"
                    :maxLength="100"
                    placeholder="Ingresa apelllido materno"
                    v-model="user.motherLastName"
                    missingErrorMessage="Ingresa apelllido materno."
                  /> -->
                </div>
              </div>
            </article>
            <article class="c-grid--services__item c-section--dateContent">
              <div class="c-section--content-date-designation">
                <h3>Mis datos de contacto</h3>
              </div>
              <div v-show="!editProfile" class="c-section--content-date">
                <div class="date-info">
                  <div class="date-info-content">
                    <div class="date-info-content-box">
                      <p class="info-black">N° Celular</p>
                      <p class="info-light">{{ user.phoneNumber }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="editProfile" class="c-section--content-date">
                <div class="form-field">
                  <InputText
                    phone
                    label="N° Celular"
                    :maxLength="9"
                    placeholder="Ingresa un teléfono"
                    v-model="user.phoneNumber"
                    missingErrorMessage="Ingresa un número de teléfono."
                  />
                </div>
              </div>
            </article>
            <article class="c-grid--services__item c-section--dateContent">
              <div class="c-section--content-date-designation">
                <h3>Identificación</h3>
              </div>
              <div v-show="!editProfile" class="c-section--content-date">
                <div class="date-info">
                  <div class="date-info-content">
                    <div class="date-info-content-box">
                      <p class="info-black">Tipo de documento</p>
                      <p class="info-light">DNI</p>
                    </div>
                  </div>
                  <div class="date-info-content">
                    <div class="date-info-content-box">
                      <p class="info-black">N° Documento</p>
                      <p class="info-light">{{ user.dni }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="editProfile" class="c-section--content-date">
                <div class="form-field">
                  <InputText
                    label="N° DNI"
                    :maxLength="8"
                    placeholder="Ingresa tu dni"
                    v-model="user.dni"
                    missingErrorMessage="Ingresa tu dni."
                  />
                </div>
              </div>
            </article>
            <article v-show="!editProfile" class="c-grid--services__item c-section--dateContent">
              <div class="c-section--content-date-designation">
                <h3>¿Tienes alguna duda? Escríbenos</h3>
              </div>
              <div class="c-section--content-date">
                <div class="date-info">
                  <!-- <div class="date-info-content">
                    <div class="date-info-content-box">
                      <p class="info-black">Nombre</p>
                      <p class="info-light">Atencion al cliente</p>
                    </div>
                  </div> -->
                  <div class="date-info-content">
                    <div class="date-info-content-box">
                      <p class="info-black">Teléfono</p>
                      <a class="info-light manager-phone" :href='`https://wa.me/51998140976`' target="_blank">
                        <p>998 140 976</p>
                        <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#a)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M20.503 4.438A11.87 11.87 0 0 0 12.05.933C5.463.933.103 6.293.1 12.883a11.924 11.924 0 0 0 1.595 5.973L0 25.05l6.335-1.662a11.935 11.935 0 0 0 5.71 1.455h.005c6.586 0 11.948-5.361 11.95-11.95a11.877 11.877 0 0 0-3.497-8.454ZM12.05 22.824h-.004a9.92 9.92 0 0 1-5.055-1.385l-.363-.215-3.76.986 1.004-3.666-.236-.375a9.907 9.907 0 0 1-1.518-5.286C2.12 7.407 6.576 2.95 12.055 2.95a9.865 9.865 0 0 1 7.021 2.913 9.87 9.87 0 0 1 2.907 7.027c-.003 5.476-4.458 9.932-9.933 9.932Zm5.448-7.439c-.298-.15-1.766-.871-2.04-.971-.274-.1-.473-.15-.672.15-.198.299-.77.971-.945 1.17-.174.2-.348.224-.647.075-.298-.15-1.26-.465-2.401-1.482-.888-.792-1.487-1.77-1.661-2.069-.175-.299-.019-.46.13-.609.134-.134.299-.349.448-.523.15-.174.2-.299.299-.498.1-.2.05-.373-.025-.523-.074-.15-.671-1.62-.92-2.217-.243-.582-.49-.503-.672-.513a12.92 12.92 0 0 0-.572-.01c-.2 0-.523.075-.797.374s-1.045 1.021-1.045 2.49c0 1.47 1.07 2.89 1.22 3.09.149.199 2.105 3.214 5.1 4.508.712.308 1.268.492 1.702.63.715.227 1.366.195 1.88.118.574-.086 1.767-.723 2.016-1.42.249-.697.249-1.296.174-1.42-.074-.124-.274-.2-.572-.349Z"
                              fill="#25D366"
                            />
                          </g>
                          <defs>
                            <clipPath id="a">
                              <path fill="#fff" d="M0 .933h24v24.133H0z"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article class="c-grid--services__item c-section--dateContent">
              <div class="c-section--content-date-designation">
                <h3>Empresa</h3>
              </div>
              <div v-show="!editProfile" class="c-section--content-date">
                <div class="date-info">
                  <div class="date-info-content">
                    <div class="c-section--content-check">
                      <div class="switch" style="cursor: pointer">
                        <label
                          ><input
                            type="checkbox"
                            v-model="isEntrepreneur"
                            @click="handleEditProfile2" />
                          <span class="lever"></span
                        ></label>
                      </div>
                      <p>
                        Soy emprendedor <br />
                        Mi cuenta será de uso para mejorar y potenciar mi
                        emprendimiento o negocio
                      </p>
                    </div>
                  </div>
                  <div class="date-info-content" v-show="isEntrepreneur">
                    <div class="date-info-content-box">
                      <p class="info-black">Razon Social</p>
                      <p class="info-light">{{ company.businessName }}</p>
                    </div>
                  </div>
                  <div class="date-info-content" v-show="isEntrepreneur">
                    <div class="date-info-content-box">
                      <p class="info-black">RUC</p>
                      <p class="info-light">{{ company.ruc }}</p>
                    </div>
                  </div>
                  <div class="date-info-content" v-show="isEntrepreneur">
                    <div class="date-info-content-box">
                      <p class="info-black">Envíos al mes</p>
                      <p class="info-light">{{ frequenceLabel }}</p>
                    </div>
                  </div>
                  <div class="date-info-content" v-show="isEntrepreneur">
                    <div class="date-info-content-box">
                      <p class="info-black">Categoría de negocio</p>
                      <p class="info-light">{{ categoriesSelectedListed }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="editProfile" class="c-section--content-check">
                <div class="switch">
                  <label
                    ><input type="checkbox" v-model="isEntrepreneur" />
                    <span class="lever"></span
                  ></label>
                </div>
                <p>
                  Soy emprendedor <br />
                  Mi cuenta será de uso para mejorar y potenciar mi
                  emprendimiento o negocio
                </p>
              </div>
              <div
                v-show="editProfile && isEntrepreneur"
                class="c-section--content-date"
              >
                <div class="form-field">
                  <InputText
                    label="Razon Social"
                    :maxLength="100"
                    placeholder="Ingresa razon social"
                    v-model="company.businessName"
                    missingErrorMessage="Ingresa razon social."
                  />
                  <InputText
                    phone
                    label="RUC"
                    :maxLength="11"
                    placeholder="Ingresa tu RUC"
                    v-model="company.ruc"
                    missingErrorMessage="Ingresa tu RUC."
                  />
                  <CustomSelect
                    label="Cantidad de envíos al mes"
                    placeholder="Selecciona un rango"
                    :optionList="frequencieslist"
                    v-model="user.frequency"
                    errorMessage="Selecciona la cantidad de envíos que realizas al mes."
                  />
                  <BusinessCategorySelect
                    :categoriesList="categoriesList"
                    v-model="user.subCategoriesSelected"
                    label="Categoría de negocio"
                    placeholder="Selecciona la(s) categoría(s)"
                    errorMessage="Selecciona la(s) categoría(s) de tu negocio."
                    ref="categorySelectRef"
                  />
                </div>
              </div>
            </article>
          </div>
        </div>
      </article>
      <div class="c-nav c-nav--floating-footer-sticky" />
      <div class="flex justify-center">
        <div style='width: 100px'>
          <ButtonDefinitive
            :label="buttonText"
            @click="handleEditProfile()"
          />
        </div>
      </div>

      <div class="flex justify-center flex-col">
        <a @click="confirmDeleteAccount" class="mt-5" style="color: #E50000; text-decoration: underline; cursor:pointer;">Eliminar cuenta </a>
      </div>
      
    </div>
    
    
    <div v-show="type === 'password'" id="password" class="c-height">
      <article class="c-content--sendPoint c-scrollbar">
        <div class="c-grid--services__container-m">
          <div class="c-section--content-article">
            <article
              class="c-grid--services__item c-section--content-current-pass"
            >
              <h2 class="c-section--change-pass">Cambiar clave</h2>
              <p class="c-section--change-information">
                Para proceder ingresa los datos para continuar
              </p>
            </article>
          </div>
          <div class="form-field" style="padding-top: 10px; margin: 0 14px;">
            <InputText
              password
              label="Clave actual"
              :maxLength="150"
              autocomplete="new-password"
              placeholder="Ingresa una contraseña"
              v-model="password.current"
              missingErrorMessage="Ingresa una contraseña."
            />
            <InputText
              password
              label="Clave nueva"
              :maxLength="150"
              autocomplete="new-password"
              placeholder="Repite la contraseña"
              v-model="password.new"
              missingErrorMessage="Repite la contraseña."
            />
          </div>
        </div>
      </article>
      <div class="c-nav c-nav--floating-footer-sticky">
        <ButtonDefinitive
          label='Cambiar'
          @click="savePassword"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '../components/Form/InputText.vue'
import CustomSelect from '../components/Form/CustomSelect.vue'
import BusinessCategorySelect from '../components/Form/BusinessCategorySelect.vue'
import { api2 } from '../services/api'
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: "ProfileView",
  components: {
    InputText,
    CustomSelect,
    BusinessCategorySelect,
    ButtonDefinitive
  },
  data() {
    return {
      user: {
        name: '',
        motherLastName: '',
        phoneNumber: '',
        dni: '',
        address: '',
        city: '',
        frequency: '',
        subCategoriesSelected: [],
      },
      type: "profile",
      tabSelect: "profile",
      editProfile: false,
      company: {
        businessName: "",
        ruc: "",
      },
      isEntrepreneur: false,
      frequencieslist: [
        { id: 'Carbon', label: '1 a 10 envíos' },
        { id: 'Bronce', label: '11 a 25 envíos' },
        { id: 'Silver', label: '26 a 50 envíos' },
        { id: 'Gold', label: '51 a más envíos' }
      ],
      categoriesList: [],

      // password
      password: {
        current: "",
        new: "",
      },
      managersPhone: {
        'Rosmery': '922467945',
        'Jose Luis': '926616799'
      }
    };
  },

  methods: {
    async getCategories() {
      try {
        const { data } = await api2.get(`categories`)
        return data.data.map((category, i) => {
          if(category.nombre === 'Otros') {
            category.inverseParent.push({ categoriaId: 18, nombre: '', is_other_category: true, keywords: "[]" })
          }
          return {
            id: i + 1,
            category_name: category.nombre,
            is_expanded: i === 0,
            sub_categories: category.inverseParent.map(subCategory => ({
                id: subCategory.categoriaId,
                name: subCategory.nombre,
                key_words: JSON.parse(subCategory.keywords),
                is_other_category: subCategory.is_other_category
              }))
          }
        })
      } catch (e) {
        console.log({e})
        return []
      }
    },
    handleBackButton() {
      window.location.href = process.env.VUE_APP_WEBURL;
    },
    handleEditProfile() {
      if (this.editProfile && !this.validateInputs()) return;
      this.editProfile = !this.editProfile;
      if (this.editProfile) return;
      this.trackEvent('editar_perfil', 'profile')
      this.save();
    },
    handleEditProfile2() {
      this.editProfile = true;
      this.isEntrepreneur = !this.isEntrepreneur;
    },
    validateInputs() {
      if (this.user.name === "") {
        this.showError("Por favor ingrese su nombre");
        return false;
      }

      if (this.user.lastName === "") {
        this.showError("Por favor ingrese su apellido");
        return false;
      }

      // if (this.user.motherLastName === "") {
      //   this.showError("Por favor ingrese su apellido");
      //   return false;
      // }

      if (this.user.phoneNumber === "") {
        this.showError("Por favor ingrese su número de teléfono");
        return false;
      }

      // if (this.user.dni === "") {
      //   this.showError("Por favor ingrese su número de DNI");
      //   return false;
      // }

      // if (this.user.address === "") {
      //   this.showError("Por favor ingrese su dirección");
      //   return false;
      // }

      // if (this.user.city === "") {
      //   this.showError("Por favor ingrese su ciudad");
      //   return false;
      // }

      if (this.isEntrepreneur && !this.company.businessName) {
        this.showError("Por favor ingrese la Razon Social");
        return false;
      }

      if (this.isEntrepreneur && !this.company.ruc) {
        this.showError("Por favor ingrese el RUC");
        return false;
      }

      return true;
    },
    async save() {
      if (!this.validateInputs()) {
        return;
      }

      this.showLoading();

      const otherCategorySelected = this.user.subCategoriesSelected.find(subCategory => subCategory.id === 18)
      const payload = {
        name: this.user.name,
        lastName: this.user.lastName,
        motherLastName: this.user.motherLastName,
        phoneNumber: this.user.phoneNumber,
        dni: this.user.dni,
        companyBusinessName: this.company.businessName,
        address: this.user.address,
        city: this.user.city,
        rubroId: 1,
        zipCode: null,
        isEntrepreneur: this.isEntrepreneur,
        businessName: this.company.businessName,
        ruc: this.company.ruc,
        frequency: this.user.frequency,
        subCategoriesSelected: this.user.subCategoriesSelected.map(subCategory => subCategory.id),
        otherCategoryDescription: otherCategorySelected?.name || ''
      };

      console.log(payload)

      await this.$store
        .dispatch("EDIT_USER_PROFILE", payload)
        .then((success) => {
          this.$store.dispatch("USER_SET_NEWCLIENT", success.isNewClient);
          this.showOk("Datos actualizados");
        })
        .catch((error) => {
          this.$swal.close();
          this.showError(
            error || "Sesion expirada, por favor vuelva a ingresar."
          );
        });
    },
    async savePassword() {
      this.showLoading();
      const payload = {
        CurrentPassword: this.password.current,
        NewPassword: this.password.new,
      };
      await this.$store
        .dispatch("EDIT_USER_PASSWORD", payload)
        .then(() => {
          this.showOk("Clave actualizada");
        })
        .catch((error) => {
          this.$swal.close();
          this.showError(
            error || "Sesion expirada, por favor vuelva a ingresar."
          );
        })
        .finally(() => {
          this.password.current = "";
          this.password.new = "";
        });
    },
    getCategoriesSelectedIds(categories) {
      try {
        let subCats = this.categoriesList.map(cat => cat.sub_categories)
        subCats = subCats.flat()
        return categories.map(category => {
          const categoryFound = subCats.find(subCat => subCat.id === category.categoryId)
          if(category.categoryId === 18) categoryFound.name = category.name
          return categoryFound
        })
      } catch (e) {
        console.log(e)
        return []
      }
    },
    makeCall() {
      const phoneNumber = this.managersPhone[this.user.accountManager]
      window.location.href = `tel:${  phoneNumber}`;
    },
    
    confirmDeleteAccount() {
      this.$swal({
        title: '¿Estás seguro que deseas eliminar tu cuenta?',
        text: 'Recuerda que esta acción es irreversible.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#E50000',
        cancelButtonColor: '#000',
        confirmButtonText: 'Sí, eliminar cuenta',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAccount();
        }
      });
    },

    async deleteAccount() {
      try {
        this.showLoading();
        await this.$store.dispatch('DELETE_USER_ACCOUNT', this.user.id);
        this.showOk('Tu cuenta ha sido eliminada');
        await this.$store.dispatch('LOGOUT');
        window.location.href = process.env.VUE_APP_WEBURL;
      } catch (error) {
        this.$swal.close();
        this.showError(error || 'No se pudo eliminar la cuenta. Por favor, intenta de nuevo más tarde.');
      }
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.$store.dispatch("STYLE_MAP_SAIO", false);
      this.closeSidebar();
      this.initTabs();
      this.$store.dispatch(
        "STYLE_CUSTOM_DIV",
        process.env.VUE_APP_STYLE_HOME_FAVORITES
      );

      this.showLoading()
      this.getCategories()
        .then(result => {
          this.categoriesList = result
          return this.$store.dispatch("GET_USER_PROFILE")
        })
        .then(response => {
          this.user.name = response?.name || ''
          this.user.lastName = response?.lastName || ''
          this.user.motherLastName = response?.motherLastName || ''
          this.user.dni = response?.dni || ''
          this.user.phoneNumber = response?.phoneNumber || ''
          this.user.city = response?.city || ''
          this.user.accountManager = response?.accountManager || ''
          this.user.frequency = response?.frequency || ''
          this.user.subCategoriesSelected = this.getCategoriesSelectedIds(response?.categories || [])
          this.user.otherCategoryDescription = ''
          this.user.id = response?.clientId

          this.company.businessName = response.company?.businessName
          this.company.ruc = response.company?.ruc
          this.isEntrepreneur = response.rol === "EMP"

          this.$refs.categorySelectRef.setCategoriesSelected(this.user.subCategoriesSelected)
          this.$swal.close()
        })
        .catch(e => {
          console.log(e)
          Error("Error al obtener el detalle del pedido");
        });
    });
    if (this.$store.getters.NEWCLIENT === true) {
      this.showWarning(
        "Por favor complete sus datos para poder realizar su pedido"
      );
    }
  },
  computed: {
    buttonText() {
      return this.editProfile ? "Guardar" : "Editar";
    },
    frequenceLabel() {
      const frequenceSelected = this.frequencieslist.find(freq => freq.id === this.user.frequency)
      return frequenceSelected?.label || 'No seleccionado'
    },
    categoriesSelectedListed() {
      const catsSelectedName = this.user.subCategoriesSelected.map(cat => cat.name)
      return new Intl.ListFormat('es').format(catsSelectedName) || 'No seleccionado'
    }
  },
};
</script>

<style lang="scss" scoped>
.form-field {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.manager-phone {
  cursor: pointer;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  gap: 10px;
}

.c-content--profile {
    height: calc(100% - 126px) !important;
}
</style>
