<template>
  <div class="c-content c-content--app c-content--full_height pb-0">
    <div>
      <div class="c-nav c-nav--floating-top">
        <div class="c-content-vertical-align">
          <div
            class="c-nav--floating-top__button-back"
            @click="handleBackButton"
          ></div>
          <h6 class="c-text-center">Mis favoritos</h6>
        </div>
      </div>
    </div>
    <div class="c-section--content-tab-c c-m-top-for-nav-md">
      <ul class="c-nav--tab-d-c tabs">
        <li class="c-tab-button tab">
          <a
            href="javascript:void(0)"
            v-bind:class="[type === 'directions' ? 'active' : '']"
            @click="type = 'directions'"
            >Direcciones
          </a>
        </li>
        <li class="c-tab-button-n tab">
          <a
            href="javascript:void(0)"
            v-bind:class="[type === 'contacts' ? 'active' : '']"
            @click="type = 'contacts'"
            >Contactos
          </a>
        </li>
      </ul>
    </div>
    <div
      id="directions"
      class="c-height"
      v-bind:class="[type === 'directions' ? 'active' : '']"
      v-show="type === 'directions'"
    >
      <article class="c-content--sendPoint c-scrollbar">
        <div class="c-content--app_container">
          <div class="c-grid--services__container-m">
            <div class="c-section--content-article">
              <article
                class="c-section--article"
                style="cursor: pointer"
                v-for="address in addresses"
                v-bind:key="address.favoriteLocationId"
              >
                <div class="c-flex">
                  <div class="c-icon-start" @click="openDetailAdress(address)">
                    <img src="~@/assets/img/icon/icon-star.svg" alt="" />
                  </div>
                  <div
                    class="content-article-directions"
                    @click="openDetailAdress(address)"
                  >
                    <div class="c-section--article-paragraph-n">
                      {{ address.address }}
                    </div>
                    <div class="c-section--article-paragraph">
                      {{ address.name }}
                    </div>
                  </div>
                  <unicon
                    name="edit"
                    fill="black"
                    style="
                      margin-right: 10px;
                      padding: 10px 0px;
                      cursor: pointer;
                      display: flex;
                    "
                    height="30"
                    width="40"
                    @click="openDetailAdress(address)"
                  />
                  <!-- <unicon
                    name="trash"
                    fill="black"
                    style="
                      margin-right: 10px;
                      padding: 10px 0px;
                      cursor: pointer;
                      display: flex;
                    "
                    height="30"
                    width="40"
                    @click="deleteAdressConfirmation(address)"
                  /> -->
                </div>
              </article>
            </div>
          </div>
        </div>
      </article>
      <div class="c-nav c-nav--floating-footer-sticky">
        <button
          id="btnAgregarDireccion"
          class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
          @click="openAddAddress"
        >
          Agregar Dirección
        </button>
      </div>
    </div>
    <div
      id="contacts"
      class="c-height"
      v-bind:class="[type === 'contacts' ? 'active' : '']"
      v-show="type === 'contacts'"
    >
      <article class="c-content--sendPoint c-scrollbar">
        <div class="c-content--app_container">
          <div class="c-grid--services__container-m">
            <div class="c-section--content-article">
              <article
                class="c-section--article"
                style="cursor: pointer"
                v-for="contact in contacts"
                v-bind:key="contact.contactId"
              >
                <div class="c-flex">
                  <div
                    class="c-icon-person"
                    @click="openDetailContact(contact)"
                  >
                    <img src="~@/assets/img/icon/icon-user.svg" alt="" />
                  </div>
                  <div
                    class="content-article-directions"
                    @click="openDetailContact(contact)"
                  >
                    <div class="c-section--article-paragraph-n">
                      {{ contact.nombreContacto }}
                    </div>
                    <div class="c-section--article-paragraph">
                      N° {{ contact.telefonoContacto }}
                    </div>
                  </div>
                  <unicon
                    @click="openDetailContact(contact)"
                    name="edit"
                    fill="black"
                    style="
                      margin-right: 10px;
                      padding: 10px 0px;
                      cursor: pointer;
                    "
                    height="30"
                    width="40"
                  />
                  <!-- <unicon
                    @click="deleteContactConfirmation(contact)"
                    name="trash"
                    fill="black"
                    style="
                      margin-right: 10px;
                      padding: 10px 0px;
                      cursor: pointer;
                      display: flex;
                    "
                    height="30"
                    width="40"
                  /> -->
                </div>
              </article>
            </div>
          </div>
        </div>
      </article>
      <div class="c-nav c-nav--floating-footer-sticky">
        <button
          id="btnAgregarContacto"
          class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
          @click="openAddContact"
        >
          Agregar Contacto
        </button>
      </div>
    </div>
    <modal-address
      id="modal-new-address"
      disableAddressInput="false"
      disableCloseButton="false"
      :adress="selectedAdress"
    />
    <modal-contact
      id="modal-new-contact"
      @getAddContactData="getNewContact"
      @saveChangesContact="saveChangesContact"
      disableFavoriteSwitch="true"
      :contact="selectedContact"
    />
  </div>
</template>

<script>
import ModalAddress from "../components/Modals/ModalAddress.vue";
import ModalContact from "../components/Modals/ModalContact.vue";
import {
  deleteFavoriteLocation,
  deleteFavoriteContact,
} from "../services/client";

export default {
  name: "FavoritesView",
  title: "Favoritos - Sharf",
  components: {
    ModalAddress,
    ModalContact,
  },
  data() {
    return {
      type: "directions",
      selectedContact: null,
      selectedAdress: null,
    };
  },
  methods: {
    deleteAdressConfirmation(adress) {
      console.log(
        "🚀 ~ file: Favorites.vue:200 ~ deleteAdressConfirmation ~ adress",
        adress
      );

      const vm = this;

      this.$swal({
        title: "Confirme",
        html: "¿Desea eliminar esta dirección de favoritos?",
        icon: "question",
        confirmButtonText: "Aceptar",
        showDenyButton: true,
        denyButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("confirmed");
          vm.deleteAdress(adress.favoriteLocationId);
        }
      });
    },
    async deleteAdress(locationId) {
      await deleteFavoriteLocation(locationId);
      await this.fetchAddresses();
    },
    deleteContactConfirmation(contact) {
      const vm = this;

      this.$swal({
        title: "Confirme",
        html: "¿Desea eliminar este contacto de favoritos?",
        icon: "question",
        confirmButtonText: "Aceptar",
        showDenyButton: true,
        denyButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("confirmed");
          vm.deleteContact(contact.contactId);
        }
      });
    },
    async deleteContact(contactId) {
      await deleteFavoriteContact(contactId);
      await this.fetchContacts();
    },
    handleBackButton() {
      window.location.href = process.env.VUE_APP_WEBURL;
    },
    async getNewContact(newContact) {
      const payload = {
        NombreContacto: newContact.nombreContacto,
        TelefonoContacto: newContact.telefonoContacto,
        CorreoContacto: newContact.correoContacto,
        NroDocumentoContacto: newContact.nroDocumentoContacto,
        DocumentType: newContact.documentType,
      };

      this.showLoading("Guardando Contacto...");
      await this.$store
        .dispatch("POST_CONTACTS", payload)
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            //
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });

      await this.fetchContacts();
    },
    async saveChangesContact(contact) {
      const payload = {
        ContactId: contact.contactId,
        NombreContacto: contact.nombreContacto,
        TelefonoContacto: contact.telefonoContacto,
        CorreoContacto: contact.correoContacto,
        NroDocumentoContacto: contact.nroDocumentoContacto,
        DocumentType: contact.documentType,
      };
      this.showLoading("Guardando Contacto...");
      await this.$store
        .dispatch("PUT_CONTACTS", payload)
        .then((success) => {
          this.$swal.close();
          if (success.status !== 201) {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
      await this.fetchContacts();
    },
    openAddContact() {
      this.selectedContact = null;
      this.openModal("modal-new-contact");
    },
    openDetailContact(contact) {
      this.selectedContact = null;
      this.selectedContact = contact;
      this.openModal("modal-new-contact");
    },
    openAddAddress() {
      this.selectedAdress = null;
      this.openModal("modal-new-address");
    },
    openDetailAdress(adress) {
      this.selectedAdress = null;
      this.selectedAdress = adress;
      this.openModal("modal-new-address");
    },
    async fetchAddresses() {
      await this.$store
        .dispatch("GET_FAVORITE_LOCATIONS")
        .then((success) => {
          if (!success) {
            this.showError("Por favor, vuelva a iniciar sesion.");
          } else {
            this.$swal.close();
          }
        })
        .catch(() => {
          this.showError("Por favor, vuelva a iniciar sesion.");
        });
    },
    fetchContacts() {
      this.showLoading();
      this.$store
        .dispatch("GET_CONTACTS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const contacts = success.data.result.list;
            this.$store.dispatch("GENERAL_SET_CONTACTS", contacts);
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.$store.dispatch("STYLE_MAP_SAIO", false);
      this.closeSidebar();
      this.initTabs();
      this.showLoading();
      await this.fetchAddresses();
      await this.fetchContacts();
      this.$store.dispatch(
        "STYLE_CUSTOM_DIV",
        process.env.VUE_APP_STYLE_HOME_FAVORITES
      );
      this.$swal.close();
    });
  },
  computed: {
    addresses() {
      return this.$store.getters.GENERAL_FAVORITE_ADDRESSES;
    },
    contacts() {
      return this.$store.getters.GENERAL_CONTACTS;
    },
  },
};
</script>

<style></style>
